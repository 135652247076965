import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
    Grid,
    Button,
    FormControl,
    Slider,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import Title from "../../../Title";
import { useStyles } from "./styles";
import Dropdown from "../../../Dropdowns/SingleSelect";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import Scheduler from "material-ui-cron";
import { useFlags } from "launchdarkly-react-client-sdk";
import LPRConditional from "../RoutineConditionals/LPRConditional";
import { useForm, Controller } from "react-hook-form";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectContextEntity} from "../../../../state/slices/CoreEntity";

const RoutineWhen = ({ currentRoutine, setCurrentRoutine, handleSwitch, events, isSchedule, isEvent, setConditionalValid }) => {
    const classes = useStyles();
    const [entities, setEntities] = useState([]);
    const { facilityID } = useCurrentFacility();
    const useCoreEntitySlice = useCoreEntityContext();

    const devicesInFacility = useSelector(
        (state) => _.orderBy((useCoreEntitySlice ? selectContextEntity(state).children : state.entities.Context.children), ["name"], ["asc"]),
        shallowEqual
    );
    const { scheduledRoutines } = useFlags();

    let defaults = {
        routine: {
            debounceMinutes: currentRoutine?.debounceMinutes ?? 0
        }
    };

    const { 
        control,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: defaults,
        mode: "onChange",
    });

    useEffect(() => {
        setValue("routine.debounceMinutes", currentRoutine.debounceMinutes);
    }, [currentRoutine?.debounceMinutes, setValue]);


    const GetDevices = () => {
        if(Array.isArray(devicesInFacility)) {
            var devices = devicesInFacility?.map((device) => { return { name: device?.name, value: device?.entityid } });
            devices.push({ name: "Entire Facility", value: facilityID });
            setEntities(devices);
        }
    }

    const handleCronSelect = (e) => {
        setCurrentRoutine({ ...currentRoutine, cronSchedule: e });
    }

    const handleSelectEvent = (e) => {
      switch(e){
        case 29:
          setCurrentRoutine({ ...currentRoutine, systemEventId: e, conditions: [{ conditiontypeid : 1, payload: {values: []} }] });
        break;
        default:
          setConditionalValid(true);
          setCurrentRoutine({ ...currentRoutine, systemEventId: e, conditions: null });
      }
    }

    const handleSelectEntity = (e) => {
        setCurrentRoutine({ ...currentRoutine, entityId: e });
    }

    const handleSelectDebounce = (e, targetValue) => {
        setCurrentRoutine({ ...currentRoutine, debounceMinutes: targetValue });
    }

    useEffect(() => {
        GetDevices();
    }, []);

    return (
        <Grid container className={clsx([classes.routineWhenContainer])}>
            <Grid item xs={12}>
                <Title><h5 className={clsx([classes.whenThenText])}>When...</h5></Title>
                <hr/>
            </Grid>
            <Grid container>
                <Grid container item xs={12} md={isSchedule ? 4 : scheduledRoutines ? 6 : 12} className={clsx([classes.routineEventButtonContainer, scheduledRoutines ? classes.routineBorder : ""])} alignItems="center" justify="center">
                    {isEvent &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                ...this event happens
                            </Grid>
                            <Grid item xs={12}>
                                <Dropdown
                                    className={clsx([classes.systemEventDropdown])}
                                    variant="outlined"
                                    data-testid="dropdown-select-event"
                                    data-id={"systemEventSelect"}
                                    name="Event"
                                    options={events}
                                    onSelect={handleSelectEvent}
                                    value={currentRoutine?.systemEventId ?? ""}
                                    data-value={currentRoutine?.systemEventId ?? ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                ...at this location
                            </Grid>
                            <Grid item xs={12}>
                                <Dropdown
                                    data-testid="dropdown-select-location"
                                    data-id={"locationSelect"}
                                    name="Location"
                                    variant="outlined"
                                    options={entities}
                                    onSelect={handleSelectEntity}
                                    value={currentRoutine?.entityId ?? ""}
                                    data-value={currentRoutine?.entityId ?? ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                and this routine hasn&apos;t already triggered within the last
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={10}
                                lg={10}
                                data-tag="debounce-minutes"
                                className={classes.slider}
                            >
                                <Controller
                                    name="routine.debounceMinutes"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            fullWidth
                                            role="debounce-minutes"
                                        >
                                            <Slider
                                                {...field}
                                                data-testid="debounce-minutes-slider"
                                                step={1}
                                                min={0}
                                                max={60}
                                                valueLabelDisplay="on"
                                                marks={[
                                                    { value: 0, label: "0 mins" },
                                                    { value: 60, label: "60 mins" },
                                                ]}
                                                onChange={(e, value) => {
                                                    field.onChange(value);
                                                }}
                                                onChangeCommitted={(e, value) => {
                                                    field.onChange(value);
                                                    handleSelectDebounce(e, value);
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    }
                    {
                      {
                        29: <LPRConditional
                              currentRoutine={currentRoutine}
                              setCurrentRoutine={setCurrentRoutine}
                              conditionIndex={0}
                              setConditionalValid={setConditionalValid}
                            />,
                      }[currentRoutine?.systemEventId]
                    }
                    {!isEvent &&
                        <Button data-id={"eventBtn"} color="primary" size="large" variant="outlined" onClick={() => { handleSwitch(false); }}>An Event Happens</Button>
                    }
                </Grid>
                {scheduledRoutines &&
                    <Grid container item xs={12} md={isSchedule? 8 : 6} className={clsx([classes.routineScheduleButtonContainer])} alignItems="center" justify={isSchedule ? "flex-start" : "center"} data-id={"schedule-container"}>
                        {isSchedule &&
                            <Scheduler
                                setCron={handleCronSelect}
                                cron={currentRoutine?.cronSchedule}
                                setCronError={() => {}}
                                isAdmin={true} // This is not related to being an admin user. It just allows additional scheduling options
                            />
                        }
                        {!isSchedule &&
                            <Button data-id={"scheduleBtn"} color="primary" size="large" variant="outlined" onClick={() => { handleSwitch(true); }}>A Scheduled Time Happens</Button>
                        }
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};
export default RoutineWhen;